import * as React from "react";
import { Container } from "react-bootstrap";
import Layout from "../components/Layout";
import "../scss/404.scss";
import { Link } from "gatsby";

const NotFoundPage = () => {
  return (
    <Layout title="Page not Found" description="">
      <Container as={"main"} className="PageStyle">
        <span className="PageStyle__Top" />
        <section className="PageStyle__Container">
          <h1 className="PageStyle__Container PageStyle__Container__404">
            404
          </h1>
          <h2 className="PageStyle__Container PageStyle__Container__Text PageStyle__Container__Text__Sorry">
            Sorry!
            <br />
            Page not found.
          </h2>
          <h3 className="PageStyle__Container PageStyle__Container__Text PageStyle__Container__Text__PageExist">
            This page does not exist or was removed!
          </h3>
          <Link
            to="/"
            className="PageStyle__Container PageStyle__Container__Button"
          >
            Return to Sara Lee Deli
          </Link>
        </section>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;

export const Head = () => <title>Not found</title>;
